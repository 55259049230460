<template>
  <div class="forgotPassword-page container-fluid">
    <div class="container">
      <div class="forgot-box">
        <span class="smart-forex">Smart Forex </span>
        <span class="forgot-password">Forgot Password</span>
      </div>
      <div class="forgot-form account-form">
        <form @submit.prevent="submit">
          <div class="form-group col-md-12 col-sm-12 email">
            <input
              v-model="form.email"
              type="email"
              placeholder="Email*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 button-forgot">
            <button type="submit">Next</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
      email: '',
    },
  }),

  methods: {
    submit() {
      this.forgotPassword(this.form)
        .then(() => {})
        .catch(err => {
          console.warn(err)
        })
    },

    ...mapActions('User', ['forgotPassword']),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
